<template>
  <div class="add-faq-modal">
    <b-modal
      modal-class="add-faq-modal"
      id="add-faq"
      :title="$t('faq.add_faq')"
      centered
      hide-footer
    >
      <validation-observer ref="addfaqValidation">
        <b-form @submit.prevent>
          <b-form-group :label="$t('faq.question')" label-for="Question">
            <validation-provider
              #default="{ errors }"
              name="Question"
              rules="required"
            >
              <b-form-input
                type="text"
                v-model="form_data.question"
                :state="errors.length > 0 ? false : null"
                :placeholder="$t('faq.question')"
                class="custom-input"
              />
              <small class="text-danger">{{
                errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
              }}</small></validation-provider
            >
          </b-form-group>

          <b-form-group :label="$t('faq.answer')" label-for="Answer">
            <validation-provider
              #default="{ errors }"
              name="Answer"
              rules="required"
            >
              <b-form-textarea
                v-model="form_data.answer"
                :state="errors.length > 0 ? false : null"
                :placeholder="$t('faq.answer')"
              ></b-form-textarea>
              <small class="text-danger">{{
                errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
              }}</small></validation-provider
            >
          </b-form-group>

          <b-button
            type="submit"
            variant="primary"
            block
            @click="addfaqForm"
            class="primary-btn my-3"
          >
            <span>{{ $t("faq.add_faq") }}</span>
          </b-button>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BButton,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { email, required } from "@core/utils/validations/validations";

export default {
  name: "Addfaq",
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    ValidationObserver,
    ValidationProvider,
    BFormTextarea,
  },
  data() {
    return {
      form_data: {
        question: null,
        answer: null,
        is_active: 1,
      },
    };
  },
  methods: {
    addfaqForm() {
      this.$refs.addfaqValidation.validate().then((success) => {
        if (success) {
          const formData = new FormData();
          for (const key in this.form_data) {
            formData.append(key, this.form_data[key]);
          }

          this.$http
            .post("admin/FAQs", formData)
            .then((res) => {
              if (res.status === 200 || res.status === 201) {
                this.$helpers.makeToast(
                  "success",
                  res.data.message,
                  res.data.message
                );
                this.$emit("update-table", this.form_data);
                this.$bvModal.hide("add-faq");
              }
            })
            .catch((err) => {
              this.$helpers.handleError(err);
            });
        } else {
          this.$helpers.makeToast(
            "warning",
            this.$t("g.something_is_wrong"),
            this.$t("g.please_ensure_that_the_inputs_are_correct")
          );
        }
      });
    },
  },
};
</script>

<style lang="scss" src="./_AddFaqModel.scss"></style>

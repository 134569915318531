<template>
  <Loading v-if="isLoading" />
  <div v-else class="faq-list">
    <b-card>
      <div
        class="p-2 custom-search d-flex align-items-center justify-content-between mb-1"
      >
        <h1 class="m-0 page-title">{{ $t("faq.faqs") }}</h1>
        <div
          class="d-flex align-items-center"
          v-if="globalPermission.FAQs && globalPermission.FAQs.store"
        >
          <b-button variant="primary" class="btn-add" @click="addFaq">
            <feather-icon icon="PlusIcon" class="text-white" size="16" />
            {{ $t("faq.add_faqs") }}
          </b-button>
        </div>
      </div>
      <!-- table -->
      <vue-good-table
        :columns="tableHeader"
        :rows="tableBody"
        styleClass="table-custom-style vgt-table striped condensed"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        :search-options="{
          enabled: true,
          skipDiacritics: true,
          placeholder: $t('search_placeholder'),
        }"
      >
        <!-- Column: Name -->
        <template slot="table-column" slot-scope="props">
          <span v-if="props.column.label === 'name'" class="text-nowrap">
            {{ $t("faq.name") }}
          </span>
          <span v-else-if="props.column.label === 'code'" class="text-nowrap">
            {{ $t("faq.code") }}
          </span>
          <span v-else-if="props.column.label === 'show'" class="text-nowrap">
            {{ $t("faq.is_active") }}
          </span>
          <span
            v-else-if="props.column.label === 'questions'"
            class="text-nowrap"
          >
            {{ $t("faq.question") }}
          </span>
          <span
            v-else-if="props.column.label === 'Actions'"
            class="text-nowrap"
          >
            {{ $t("g.action") }}
          </span>
          <span v-else>
            {{ props.column.label }}
          </span>
        </template>

        <!-- Slot: Table Row -->
        <template slot="table-row" slot-scope="props">
          <span
            class="text-nowrap custom-toggle"
            v-if="props.column.field === 'is_active'"
          >
            <b-form-checkbox
              switch
              :disabled="globalPermission.FAQs && !globalPermission.FAQs.update"
              v-model="props.row.is_active"
              @change="(e) => handleFaqVisibility(e, props.row.id)"
              :value="1"
              :unchecked-value="0"
            />
          </span>
          <span
            class="text-nowrap custom-toggle"
            v-else-if="props.column.field === '#'"
          >
            {{ props.row.index }}
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'actions'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                text="Block Level Dropdown Menu"
                block
              >
                <template v-slot:button-content>
                  <feather-icon
                    size="16"
                    icon="MoreVerticalIcon"
                    class="text-body align-middle mr-25"
                  />
                </template>

                <b-dropdown-item
                  v-if="globalPermission.FAQs && globalPermission.FAQs.update"
                  @click="editFaq(props.row)"
                >
                  <feather-icon icon="EditIcon" class="mr-50" />
                  <span>{{ $t("g.edit") }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="globalPermission.FAQs && globalPermission.FAQs.destroy"
                  @click="deletefaq(props.row.id)"
                >
                  <feather-icon icon="Trash2Icon" class="mr-50" />
                  <span>{{ $t("g.delete") }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
        <template slot="emptystate">
          <div class="empty-table-message">
            <!-- Your custom content for the empty state goes here -->
            <p>{{ $t("noData") }}</p>
          </div>
        </template>
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-center flex-wrap">
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
    <AddfaqModel @update-table="update" />

    <b-modal
      modal-class="edit-faq-modal"
      id="edit-faq"
      :title="$t('faq.edit_faq')"
      centered
      hide-footer
    >
      <validation-observer ref="editfaqValidation">
        <b-form @submit.prevent>
          <b-form-group :label="$t('faq.question')" label-for="Question">
            <validation-provider
              #default="{ errors }"
              name="Question"
              rules="required"
            >
              <b-form-input
                type="text"
                v-model="page_data.question"
                :state="errors.length > 0 ? false : null"
                :placeholder="$t('faq.question')"
                class="custom-input"
              />
              <small class="text-danger">{{
                errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
              }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group :label="$t('faq.answer')" label-for="Answer">
            <validation-provider
              #default="{ errors }"
              name="Answer"
              rules="required"
            >
              <b-form-textarea
                v-model="page_data.answer"
                :state="errors.length > 0 ? false : null"
                :placeholder="$t('faq.answer')"
              ></b-form-textarea>
              <small class="text-danger">{{
                errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
              }}</small>
            </validation-provider>
          </b-form-group>

          <b-button
            type="submit"
            variant="primary"
            block
            @click="editfaqForm"
            class="primary-btn my-3"
          >
            <span>{{ $t("faq.edit_faq") }}</span>
          </b-button>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BPagination,
  BCard,
  BDropdownItem,
  BDropdown,
  BButton,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BFormSelect,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Loading from "@/components/shared/loading/loading.vue";
import AddfaqModel from "@/components/faq/AddfaqModel/AddFaqModel.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { email, required } from "@core/utils/validations/validations";

export default {
  name: "FAQList",
  components: {
    VueGoodTable,
    BPagination,
    BCard,
    BButton,
    Loading,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    AddfaqModel,
    ValidationObserver,
    ValidationProvider,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BFormSelect,
  },
  data() {
    return {
      page_data: null,
      isLoading: true,
      pageLength: 10,
      searchTerm: "",
      isEdit: false,
      showModel: false,
      tableBody: [],
      tableHeader: [
        {
          label: "#",
          field: "#",
          sortable: false,
        },
        {
          label: "questions",
          field: "question",
        },
        {
          label: "show",
          field: "is_active",
          sortable: false,
        },
        {
          label: "Actions",
          field: "actions",
          sortable: false,
        },
      ],
    };
  },
  beforeMount() {
    this.getfaqList();
  },
  created() {
    if (
      this.globalPermission.FAQs &&
      !(this.globalPermission.FAQs.show || this.globalPermission.FAQs.destroy)
    ) {
      this.tableHeader = this.tableHeader.filter(
        (ele) => ele.label !== "Actions"
      );
    }
  },
  methods: {
    async getfaqList() {
      try {
        const response = (await this.$http.get("admin/FAQs")).data.data;
        this.tableBody = response;
        this.tableBody.map((el, index) => {
          (el.index = index + 1), (el.is_active = +el.is_active);
        });
      } catch (error) {
        this.$helpers.handleError(error);
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },
    async deletefaq(faqId) {
      try {
        this.$helpers.sweetAlertConfirm(
          this.$t("are_you_sure"),
          this.$t("you_wont_be_able_to_revert_this_action"),
          "warning",
          this.$t("g.yes_delete_it"),
          this.$t("g.cancel-button"),
          () => this.handledeletefaq(faqId)
        );
        this.overlayLoading = true;
      } catch (error) {
        this.$helpers.handleError(error);
        this.isError = true;
      } finally {
        this.overlayLoading = false;
      }
    },
    handledeletefaq(faqId) {
      this.$http
        .delete(`admin/FAQs/${faqId}`)
        .then((res) => {
          if (res.status === 200) {
            this.getfaqList();
          }
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    async handleFaqVisibility(checked, id) {
      try {
        this.overlayLoading = true;
        const data = {
          is_active: checked,
        };
        const res = await this.$http.post(`admin/FAQs/${id}?_method=put`, data);
        if (res.status > 299) {
          throw Error();
        }
        this.$helpers.makeToast("success", res.data.message, res.data.message);
      } catch (err) {
        this.$helpers.handleError(err);
      } finally {
        this.overlayLoading = false;
      }
    },
    addFaq() {
      this.showModel = true;
      this.$bvModal.show("add-faq");
    },
    editFaq(data) {
      this.page_data = data;
      this.$bvModal.show("edit-faq");
    },
    editfaqForm() {
      this.$refs.editfaqValidation.validate().then((success) => {
        if (success) {
          const formData = new FormData();
          for (const key in this.page_data) {
            formData.append(key, this.page_data[key]);
          }

          this.$http
            .post(`admin/FAQs/${this.page_data.id}?_method=put`, formData)
            .then((res) => {
              if (res.status === 200 || res.status === 201) {
                this.$helpers.makeToast(
                  "success",
                  res.data.message,
                  res.data.message
                );
                this.$bvModal.hide("edit-faq");
              }
            })
            .catch((err) => {
              this.$helpers.handleError(err);
            });
        } else {
          this.$helpers.makeToast(
            "warning",
            this.$t("g.something_is_wrong"),
            this.$t("g.please_ensure_that_the_inputs_are_correct")
          );
        }
      });
    },
    update() {
      this.getfaqList();
    },
  },
};
</script>

<style lang="scss">
@import "./index";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
